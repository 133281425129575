import { loadSession } from '../apis/hooks/useAuth'
import { navigateToIndex, navigateToPage } from '../components/blocks/pageWrappers/pageMap'
import { getItem, setItem, tokenPaths, removeItem } from './localStorage'
import { removeStoredAuthToken } from './auth-token'
import * as dayjs from 'dayjs'
import { VerificationSessionTypes } from '../types/identity'

type dependencyKeys =
    | 'verifiedIdentity'
    | 'loggedIn'
    | 'completedMitIDSessionVerifyIdentity'
    | 'completedMitIDSessionStolenBikeReportCreated'
    | 'completedMitIDSessionStolenBikeReportDeleted'

export const dependencyMap: Record<dependencyKeys, () => boolean> = {
    verifiedIdentity: isIdentityVerified,
    loggedIn,
    completedMitIDSessionVerifyIdentity,
    completedMitIDSessionStolenBikeReportCreated,
    completedMitIDSessionStolenBikeReportDeleted,
}

function isIdentityVerified(): boolean {
    const session = loadSession()
    if (session?.verified_on) {
        return true
    }
    return false
}

function loggedIn(): boolean {
    const session = loadSession()
    if (session?.identity_party_id) {
        return true
    }
    return false
}

export function getCompletedMitIDSessionData(consume = false): {
    sessionId: string
    timeCompleted: string
    sessionType: VerificationSessionTypes
} | null {
    const sessionString = getItem(tokenPaths.lastCompletedMitIDSession)
    if (sessionString == null) {
        return null
    }
    const verificationSession = JSON.parse(sessionString)
    if (consume) {
        removeItem(tokenPaths.lastCompletedMitIDSession)
    }
    return verificationSession
}

export function completedMitIDSessionMaxAge30Minutes(
    verificationType: VerificationSessionTypes
): boolean {
    const verificationSession = getCompletedMitIDSessionData()
    if (
        verificationSession != null &&
        dayjs(verificationSession.timeCompleted).isAfter(dayjs().subtract(30, 'minutes')) &&
        verificationSession.sessionType === verificationType
    ) {
        return true
    }
    return false
}

export function completedMitIDSessionVerifyIdentity(): boolean {
    return completedMitIDSessionMaxAge30Minutes('verify_identity')
}

export function completedMitIDSessionStolenBikeReportDeleted(): boolean {
    return completedMitIDSessionMaxAge30Minutes('stolen_bike_report_deleted')
}

export function completedMitIDSessionStolenBikeReportCreated(): boolean {
    return completedMitIDSessionMaxAge30Minutes('stolen_bike_report_created')
}

export function getMitIDFunctionToUse(verificationType: VerificationSessionTypes): dependencyKeys {
    switch (verificationType) {
        case 'stolen_bike_report_created':
            return 'completedMitIDSessionStolenBikeReportCreated'
        case 'stolen_bike_report_deleted':
            return 'completedMitIDSessionStolenBikeReportDeleted'
        default:
        case 'verify_identity':
            return 'completedMitIDSessionVerifyIdentity'
    }
}

export function redirectWrapper(
    redirectFunction: () => void,
    path: string,
    dependencyKeys: dependencyKeys[]
) {
    function redirect() {
        const redirect = JSON.parse(getItem(tokenPaths.redirectAfterLogin))
        if (redirect != null) {
            return redirectFunction()
        }
        for (const dependencyKey of dependencyKeys) {
            if (!dependencyMap[dependencyKey]) {
                throw new Error('dependecy not recognised')
            }
        }
        const args = {
            path,
            dependencyKeys,
        }
        setItem(tokenPaths.redirectAfterLogin, JSON.stringify(args))
        return redirectFunction()
    }
    return redirect
}

export function redirectIfEligible(newWindow = false): boolean {
    const redirect = JSON.parse(getItem(tokenPaths.redirectAfterLogin))
    if (redirect?.path?.length > 0) {
        for (const dependencyKey of redirect.dependencyKeys as dependencyKeys[]) {
            if (!dependencyMap[dependencyKey]()) {
                return false
            }
        }
        navigateToPage(redirect.path, newWindow)
        return true
    }
    return false
}

export function ensureRedirect(thisPathname: string): boolean {
    const redirect = JSON.parse(getItem(tokenPaths.redirectAfterLogin))
    if (redirect?.path?.length > 0) {
        const pathToCompare = redirect.path.split('?')[0]
        if (
            pathToCompare === thisPathname ||
            pathToCompare + '/' === thisPathname ||
            pathToCompare === thisPathname + '/'
        ) {
            removeItem(tokenPaths.redirectAfterLogin)
        } else {
            redirectIfEligible()
        }
        return true
    }
    return false
}

export function signOut() {
    removeStoredAuthToken()
    navigateToIndex(35)
}
