import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { WitnessInformation } from '../../types/reportStolen'
import { getItem, setItem, tokenPaths } from '../../utils/localStorage'
import * as dayjs from 'dayjs'

export interface ReportStolenParameters {
    bike_id: string | null
    first_name: string | undefined
    last_name: string | undefined
    email: string | undefined
    street_address: string | undefined
    city: string | undefined
    zip_code: string | undefined
    mobile: string | undefined
    telephone: string | undefined
    floor: string | undefined
    side: string | undefined
    door: string | undefined
    frame_number: string | undefined | null
    bike_category: string | undefined | null
    is_electric: boolean | undefined | null
    bike_color: string | undefined | null
    lock_status: string | undefined
    culprit_info: string | undefined
    isCulpritKnown: 'yes' | 'no' | 'unknown' | null
    witnesses: WitnessInformation[]
    hasWitness: 'yes' | 'no' | 'unknown' | null
    time_last_seen: string
    noted_time: string
    place: string | undefined
    location_id: string | undefined
    insurance_company: string | undefined
    bike_price: number | undefined | null
    policeReportRequested: boolean | undefined | null
    acknowledgement_payout: boolean | undefined | null
    acknowledgement_data_share: boolean | undefined | null
    acknowledgement_ownership_transfer: boolean | undefined | null
    cpr_number: string | undefined | null
}

export interface ReportStolenState {
    parameters: ReportStolenParameters
    errors: StateKeys[]
}

export type StateKeys =
    | 'first_name'
    | 'last_name'
    | 'email'
    | 'street_address'
    | 'city'
    | 'zip_code'
    | 'mobile'
    | 'telephone'
    | 'floor'
    | 'side'
    | 'door'
    | 'frame_number'
    | 'bike_category'
    | 'is_electric'
    | 'bike_color'
    | 'lock_status'
    | 'culprit_info'
    | 'witnesses'
    | 'time_last_seen'
    | 'noted_time'
    | 'place'
    | 'location_id'
    | 'insurance_company'
    | 'bike_price'
    | 'policeReportRequested'
    | 'acknowledgement_payout'
    | 'acknowledgement_data_share'
    | 'acknowledgement_ownership_transfer'
    | 'cpr_number'
    | 'missing_input'
    | 'missing_acknowledgement'

function createEmptyState(): ReportStolenState {
    return {
        parameters: {
            bike_id: null,
            first_name: undefined,
            last_name: undefined,
            email: undefined,
            street_address: undefined,
            city: undefined,
            zip_code: undefined,
            mobile: undefined,
            telephone: undefined,
            floor: undefined,
            side: undefined,
            door: undefined,
            frame_number: undefined,
            bike_category: undefined,
            is_electric: undefined,
            bike_color: undefined,
            lock_status: undefined,
            culprit_info: undefined,
            isCulpritKnown: null,
            witnesses: [],
            hasWitness: null,
            time_last_seen: dayjs().toISOString(),
            noted_time: dayjs().toISOString(),
            place: undefined,
            location_id: undefined,
            insurance_company: undefined,
            bike_price: undefined,
            policeReportRequested: undefined,
            acknowledgement_payout: undefined,
            acknowledgement_data_share: undefined,
            acknowledgement_ownership_transfer: undefined,
            cpr_number: undefined,
        },
        errors: [],
    }
}

function initializeState() {
    const existingValues = getItem(tokenPaths.createStolenReportDraftValues)
    if (existingValues != null) {
        return JSON.parse(existingValues)
    }
    return createEmptyState()
}

function storeNewState(newState: ReportStolenState): ReportStolenState {
    setItem(tokenPaths.createStolenReportDraftValues, JSON.stringify(newState))
    return newState
}

const slice = createSlice({
    name: 'reportStolen',
    initialState: initializeState(),
    reducers: {
        setState: (state: any, action: PayloadAction<ReportStolenParameters>) => {
            return storeNewState({
                ...state,
                parameters: action.payload,
            })
        },
        updateState: (state: any, action: PayloadAction<Partial<ReportStolenParameters>>) => {
            return storeNewState({
                ...state,
                parameters: {
                    ...state.parameters,
                    ...action.payload,
                },
            })
        },
        addError: (state: any, action: PayloadAction<Partial<StateKeys>>) => {
            return storeNewState({
                ...state,
                errors: [...state.errors, action.payload],
            })
        },
        removeError: (state: any, action: PayloadAction<Partial<StateKeys>>) => {
            return storeNewState({
                ...state,
                errors: state.errors.filter((e: StateKeys) => e !== action.payload),
            })
        },
        removeAllErrors: (state: any) => {
            return storeNewState({
                ...state,
                errors: [],
            })
        },
        reset: (state: any) => {
            return storeNewState(createEmptyState())
        },
    },
})

export const { setState, reset, updateState, addError, removeError, removeAllErrors } =
    slice.actions

export default slice.reducer
